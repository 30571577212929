import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import TextCarousel from "../components/TextCarousel"

import Layout from "../components/layout"
import WorkTeaser from "../components/WorkTeaser"
//import Image from "../components/image"
import SEO from "../components/seo"

const Solutions = () => {
  const data = useStaticQuery(
    graphql`
      query SolutionsQuery {
        allPagesJson {
          nodes {
            solutions {
              solution
            }
          }
        }
      }
    `
  )
  let solutions = [];
  let i = 0

  while(i < data.allPagesJson.nodes[0].solutions.length) {
    solutions.push(data.allPagesJson.nodes[0].solutions[i].solution)
    i++
  }

  let interval = 1800

  return (
    <TextCarousel phrases={solutions} interval={interval} />
  )
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="home-head">
      <div className="container">
        <div className="intro">
          <p>Hi, my name is Sam and I spend my day creating <Solutions /> solutions.</p>
        </div>
        <div className="home-head--bio">
          <p>I am a product designer based in Sydney, Australia.</p>
          <p>I am excited by the way design can help unleash great ideas and positively impact lives.</p>
        </div>
      </div>
    </section>

    <section className="work-teaser">
      <WorkTeaser />
    </section>
  </Layout>
)

export default IndexPage
