import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import { FaAngleRight } from "react-icons/fa";

const WorkTeaser = () => {
    const query = useStaticQuery(graphql`
        query WorkQuery {
            allMarkdownRemark(limit: 3, sort: {fields: [frontmatter___order], order: ASC}) {
            totalCount
            edges {
                node {
                id
                frontmatter {
                    title
                    order
                    category
                    featureImage {
                    childImageSharp {
                        fluid(maxWidth: 600, quality: 72) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    publicURL
                    }
                    colour
                    light
                }
                fields {
                    slug
                }
                }
            }
            }
        }
    `)

    return (
        <div className="container">
        {query.allMarkdownRemark.edges.map(({ node }) => (
            <Link className="project" to={node.fields.slug} key={node.fields.slug + 'project'} >
                <BackgroundImage
                    Tag="div"
                    className="project--content"
                    fluid={node.frontmatter.featureImage.childImageSharp.fluid}
                    style={{ position: 'absolute' }}
                >
                    <div className="project--caption">
                        <span className="title">{node.frontmatter.title}</span>
                        <span className="cat">{node.frontmatter.category}</span>
                    </div>
                </BackgroundImage>
            </Link>
        ))}
            <Link to="/projects" className="work-teaser--view-more">View more projects <FaAngleRight /></Link>
        </div>
    )
}

export default WorkTeaser